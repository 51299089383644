import {Layout, Button, Carousel, Tabs, FloatButton,Affix} from 'antd';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {useEffect, useState} from 'react';
import Script from 'react-load-script';
import $ from 'jquery';
import axios from 'axios';
import {Navigation, Pagination, Scrollbar, A11y, Autoplay} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import {Link} from 'react-router-dom';    // 路由插件
import './ContentIndex.css';
const logoImg = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/logo2.png';
const yemei = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/扩项.jpg';
const xia = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/xq/logo至于每一个介绍最下方.png';
const logo22 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/logo22.png';
const weixing = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/weixing.jpg';

const {Header, Footer, Sider, Content} = Layout;
const App = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const [top, setTop] = useState(118);
    const scroll=(y)=>{
        window.scrollTo(0, y);
    }
    function createMarkup() {
        return {
            __html: `
            <div class="nyrcon">
                <div class="nyrt"><h1 class="contact-tit">如何申请实验室认可扩项</h1></div>
                <div class="cc-pp">
                    <p><span
                    style="color: rgb(0, 0, 0);"><strong><span>（一） 认可政策要求</span></strong><br />
根据CNAS-RL01:2019《实验室认可规则》的要求： <br />
5.2.1 扩大认可范围<br />
5.2.1.1 获准认可实验室在认可有效期内可以向 CNAS 秘书处提出扩大认可范围的申请。注：对于不能满足认可要求或违反认可规定而被暂停认可的实验室，在其恢复认可资格前，CNAS 不受理其扩大认可范围申请。<br />
5.2.1.2 下列情形之一（但不限于）均属于扩大认可范围：a) 增加检测/校准/鉴定方法、依据标准/规范、检测/鉴定对象/校准仪器、项目/参数；注：增加等同采用的标准，按变更处理，不作为扩大认可范围。b) 增加检测/校准/鉴定地点；c) 扩大检测/校准/鉴定的测量范围/量程；d) 取消限制范围。<br />
5.2.1.3 CNAS 秘书处根据情况可在监督评审、复评审时对申请扩大的认可范围进行评审，也可根据获准认可实验室需要，单独安排扩大认可范围的评审。当获准认可实验室需要在监督评审或复评审的同时扩大认可范围时，应至少在现场评审前 2 个月提出扩大认可范围的申请。<br />
5.2.1.4 扩大认可范围的认可程序与初次认可相同，必须经过申请、评审、评定和批准。<br />
5.2.1.5 扩大认可范围申请的受理与评审要求，与初次认可申请相同。<br />
5.2.1.6 CNAS 秘书处原则上不允许评审组在现场评审时受理实验室提出的扩大认可范围的申请。<br />
5.2.1.7 批准扩大认可范围的条件与初次认可相同，获准认可实验室在申请扩大认可的范围内必须具备相应技术能力，符合认可准则所规定的要求。</span>
                </p>
                <p><span
                    style="color: rgb(0, 0, 0);"><strong><span>（二） 扩项申请流程</span></strong><br />
一般扩项申请的流程与实验室认可初次申请基本一致<br />
在提交扩项申请时，需要随申请书提供的资料清单如下：<br />
1.实验室法律地位的证明文件，包括法人营业执照、（非独立法人实验室适用）法人或法定代表人的授权文件（若没有变化，仅在初次申请时提供）<br />
2.实验室现行有效的质量手册（如有）和程序文件（或其他称谓）<br />
3.实验室进行最近一次完整的内部审核和管理评审的资料（初次申请时提交，扩项申请可不提供）<br />
4.实验室平面图<br />
5. 当实验室是法人实体的一部分时，两者关系的说明<br />
6.对申请认可的标准/方法现行有效性进行的核查情况（提交核查报告）<br />
7.非标方法及确认记录(证明材料)<br />
8.典型项目的检测报告/校准（参考测量）证书/鉴定文书及其不确定度评估报告<br />
9.申请“能源之星”检测的实验室还应提供：<br />
1）填写的能源之星产品分类表（从EPA网站下载）<br />
2）“能源之星”检测方法与实验室检测程序、检测人员对应一览表<br />
10.其他资料（若有请填写）</span>
                </p>
                </div>
            </div>`
        }
    }

    return (

        <>
            <FloatButton.BackTop visibilityHeight={0}  style={{zIndex:9999999999999999}}/>
            <div className="fixed is_PC"></div>
            <div className="all_width is_PC">

                <div className="warp">
                    <div className="header">
                         <div className="logo"><a href="#/ContentIndex" style={{float:"left",marginRight:15}}><img src={logoImg} height={108}/></a></div>
                        <div className="new_nav">
                           <ul>
                                <li><a href="#/ContentIndex">首页</a></li>
                                <li><a  href="#/cnas">CNAS认可</a></li>
                                <li><a  href="#/cma">CMA认定</a></li>
                                <li><a  href="#/fanghuo">防火检测</a></li>
                                <li><a  href="#/haishui">海水腐蚀</a></li>
                                <li><a  href="#/jingxiang">金相抛光布</a></li>
                                <li><a   href="#/about">关于我们</a></li>
                            </ul>
                        </div>

                        <div className="vub_B" style={{position:"fixed",top:"30px"}}>联系电话： 18957441030</div>
                    </div>
                </div>
            </div>
            <div style={{position:'relative'}}>
                {/*<img src={bannerBG}/>*/}
                <img src={yemei} width={'100%'}/>
                <div style={{position:"absolute",top:'150px',left:'50px',fontSize:50,color:'#fff',fontWeight:"bold",width:1000,textAlign:"left"}}>
                    <span>实验室CMA、CNAS复评/监督/扩项服务</span><br />
                    {/*<span style={{fontWeight:100,fontSize:31}}>专业从事实验室认可评审员、内审员、测量不确定度、能力验证、检测方法的培训。</span>*/}
                </div>
            </div>
            <div className={'service-details'} >
                <Affix offsetTop={top}>
                    <div className="l-box" >
                        <div className="s-plate">
                            <div className="d-nav">
                                <ul>
                                    <li onClick={event => scroll(400)}>
												<span>
													<i></i>认可政策要求</span>
                                    </li>
                                    <li onClick={event => scroll(778)}>
												<span>
													<i></i>扩项申请流程</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Affix>

                <div className={'r-box'}>
                    <div dangerouslySetInnerHTML={createMarkup()} />
                </div>
            </div>

            <img src={xia} width={'100%'} style={{zIndex:999,position:"relative",marginTop:80,display:"block"}}/>

             <div className="all_fnav is_PC" style={{zIndex:999,position:"relative"}}>
                 <div style={{background:'#fff',height:80}}></div>
                <div className="warp">
                    <div className={'b'}>
                        <img src={logo22} height={108} style={{float:"left"}}/>
                        <div style={{float:"right",lineHeight:'108px',fontSize:'26px',paddingRight:40}}>致力于我国实验室建设、 认证、 扩项、 监督评审、 一站式解决方案</div>
                    </div>
                    <div className="Fanya_footer_nav" style={{color:'#FFF'}}>
                        <ul>
                            <li>
                                
                                <div className="Fanya_footer_nav_TT">CMA资质认定</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>检验检测机构CMA资质认定</dt>
                                        <dt>机动车检验机构CMA资质认定</dt>
                                        <dt>司法鉴定机构CMA资质认定</dt>
                                        <dt>刑事技术机构CMA资质认定</dt>
                                    </dl>
                                </div>
                            </li>
                            <li><div className="Fanya_footer_nav_TT">CNAS认证认可</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>ISO17025检测/校准实验室CNAS认可</dt>
                                        <dt>ISO17020检验机构CNAS认可</dt>
                                        <dt>ISO15189医学实验室CNAS认可</dt>
                                        <dt>DILAC国防实验室认可</dt>
                                    </dl>
                                </div>
                            </li>
                            <li style={{marginRight:15}}>
                                <div className="Fanya_footer_nav_TT">联系我们</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>电话：0574-87636695</dt>
                                        <dt>认证热线：18957441030（胡老师）</dt>
                                        <dt>地址：宁波市高新区检验检测园冬青路258号A幢</dt>
                                        <dt></dt>
                                    </dl>
                                </div>
                            </li>
                            <li>
                                <div className="Fanya_footer_sub" style={{position:"relative"}}>
                                    <img src={weixing} width={160} />
                                    <div style={{background:'red',borderRadius:16,lineHeight:'32px',textAlign:"center",width:160,marginTop:10}}>扫码咨询</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="all_Fanya_copyright">
                <div className="warp">
                    <div className="Fanya_copyright">版权所有 ◎ 浙江安鉴检测认证服务有限公司 <a
                        href="http://beian.miit.gov.cn/" target="_blank">浙ICP备2024103308号-1</a></div>
                </div>
            </div>
        </>
);
};
export default App;
