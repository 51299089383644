// router index.js
import Index from "../pages/index";
import ContentIndex from "../pages/ContentIndex";
import cma from "../pages/cma";
import cnas from "../pages/cnas";
import train from "../pages/train";
import extend from "../pages/extend";
import about from "../pages/about";
import fushi from "../pages/jianche/fushi";
import lixue from "../pages/jianche/lixue";
import jingxiang from "../pages/jianche/jingxiang";
import wushun from "../pages/jianche/wushun";
import hanjie from "../pages/jianche/hanjie";
import chenfen from "../pages/jianche/chenfen";
import fanghuo from "../pages/jianche/fanghuo"
import haishui from "../pages/jianche/haishui"
import peixun from "../pages/peixun";
import kuoxiang from "../pages/kuoxiang";
const routers = [
    {
        path: '/',
        name: '首页',
        components: ContentIndex    // 引入pages文件下的页面
    },
    {
        path: '/ContentIndex',
        name: '首页',
        components: ContentIndex    // 引入pages文件下的页面
    },
    {
        path: '/cma',
        name: 'cma',
        components: cma    // 引入pages文件下的页面
    },
    {
        path: '/cnas',
        name: 'cnas',
        components: cnas    // 引入pages文件下的页面
    },
    {
        path: '/extend',
        name: '扩展',
        components: extend    // 引入pages文件下的页面
    },
    {
        path: '/train',
        name: '培训',
        components: train    // 引入pages文件下的页面
    },
    {
        path: '/about',
        name: '公司简介',
        components: about    // 引入pages文件下的页面
    },
    {
        path: '/fushi',
        name: '',
        components: fushi    // 引入pages文件下的页面
    },
    {
        path: '/lixue',
        name: '',
        components: lixue    // 引入pages文件下的页面
    },
    {
        path: '/jingxiang',
        name: '',
        components: jingxiang    // 引入pages文件下的页面
    },
    {
        path: '/wushun',
        name: '',
        components: wushun    // 引入pages文件下的页面
    },
    {
        path: '/hanjie',
        name: '',
        components: hanjie    // 引入pages文件下的页面
    },{
        path: '/chenfen',
        name: '',
        components: chenfen    // 引入pages文件下的页面
    },{
        path: '/fanghuo',
        name: '',
        components: fanghuo    // 引入pages文件下的页面
    },{
        path: '/haishui',
        name: '',
        components: haishui    // 引入pages文件下的页面
    },{
        path: '/peixun',
        name: '',
        components: peixun    // 引入pages文件下的页面
    },{
        path: '/kuoxiang',
        name: '',
        components: kuoxiang    // 引入pages文件下的页面
    },{
        path: '/jingxiang',
        name: '',
        components: jingxiang    // 引入pages文件下的页面
    },

]

export default routers;    // 将数组导出
