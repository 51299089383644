import {Affix, FloatButton, Descriptions, Badge} from 'antd';
import {useEffect, useState} from 'react';
import '../ContentIndex.css';
const yemei = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/jx/jx页眉.jpg';
const jx1 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/jx/jx1.jpg';
const jx2 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/jx/jx2.jpg';
const jx3 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/jx/jx3.jpg';
const jx4 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/jx/jx4.jpg';

const jxgc1 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/jx/jxgc1.jpg';
const jxgc2 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/jx/jxgc2.jpg';
const jxgc3 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/jx/jxgc3.jpg';
const jxgc4 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/jx/jxgc4.jpg';
const logoImg = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/logo2.png';
const logo22 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/logo22.png';
const weixing = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/weixing.jpg';
const App = () => {
    useEffect(() => {
        // 配置swiper播放 配置项都写这里面
        // console.log(1232112312)
        // const mySwiper = new Swiper('.swiper', {
        //     autoplay: true,
        //     loop: true
        // })
        window.scrollTo(0, 0);

    }, [])
    const [scrollPosition, setScrollPosition] = useState(0);
    const [top, setTop] = useState(118);
    const scroll = (y) => {
        window.scrollTo(0, y);
    }
    const items = [
        {
            key: '1',
            label: '供货周期',
            children: '现货',
        },
        {
            key: '2',
            label: '规格',
            children: 'FT200A-FT500A',
        },
        {
            key: '3',
            label: '应用领域',
            children: '金属材料,化工,电子,冶金,汽车配件,电气',
        },
        {
            key: '4',
            label: '主要用途',
            children: '金属材料金相制样抛光',
        },
    ];

    return (

        <>
            <FloatButton.BackTop visibilityHeight={0} style={{zIndex: 9999999999999999}}/>
            <div className="fixed is_PC"></div>
            <div className="all_width is_PC">
                <div className="warp">
                    <div className="header">
                        <div className="logo"><a href="#/ContentIndex" style={{float: "left", marginRight: 15}}><img
                            src={logoImg} height={108}/></a></div>
                        <div className="new_nav">
                            <ul>
                                <li><a href="#/ContentIndex">首页</a></li>
                                <li><a href="#/cnas">CNAS认可</a></li>
                                <li><a href="#/cma">CMA认定</a></li>
                                <li><a href="#/fanghuo">防火检测</a></li>
                                <li><a href="#/haishui">海水腐蚀</a></li>
                                <li><a href="#/jingxiang">金相抛光布</a></li>
                                <li><a href="#/about">关于我们</a></li>
                            </ul>
                        </div>
                        <div className="vub_B" style={{position: "fixed", top: "30px"}}>联系电话： 18957441030</div>
                    </div>
                </div>
            </div>
            <div style={{position: 'relative'}}>
                {/*<img src={bannerBG}/>*/}
                <img src={yemei} width={'100%'}/>
                <div style={{
                    position: "absolute",
                    top: '35%',
                    left: '50px',
                    fontSize: 50,
                    color: '#fff',
                    fontWeight: "bold",
                    width: 1000,
                    textAlign: "center"
                }}>
                    <span>金相抛光布</span><br/>
                    {/*<span>上门服务 保证一次性通过</span><br />*/}
                    {/*<span style={{fontWeight:100,fontSize:31}}>准则宣贯、文件编制、内部审核、管理评审、质量记录、技术记录。</span>*/}
                </div>
            </div>
            {/*<img src={yemei}/>*/}
            <div className={'service-details'}>
                <Affix offsetTop={top}>
                    <div className="l-box">
                        <div className="s-plate">
                            <div className="d-nav">
                                <ul>
                                    <li onClick={event => scroll(30)}>
												<span>
													<i></i>产品简介</span>
                                    </li>
                                    <li onClick={event => scroll(800)}>
												<span>
													<i></i>详细介绍</span>
                                    </li>
                                    {/*<li onClick={event => scroll(350)}>*/}
									{/*			<span>*/}
									{/*				<i></i>检测标准</span>*/}
                                    {/*</li>*/}

                                </ul>
                            </div>
                        </div>
                    </div>
                </Affix>
                <div className={'r-box'}>
                    <div className="nyrcon">
                        <div className="nyrt"><h1 className="contact-tit">金相抛光布</h1></div>
                        <div className="cc-pp">
                            <br/>
                            <h2><span style={{fontSize: 16, color: '#000'}}><strong>产品简介</strong></span></h2>
                            <Descriptions  bordered items={items}/>
                            <p>
                            <span style={{fontSize: 16, color: '#000'}}></span></p>
                            <p style={{marginTop:30}}><span
                            style={{color: '#000'}}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;浙江安鉴检测在金相检测领域已经有15年的检测经验，在15年的检测检测过程中不断优化最适合金属材料抛光的产品，这款抛光布为公司自研产品，在自研350克丝绒布中加入高分子材料使抛光布更加耐磨，产品最大优势是从粗抛到精抛（镜面）一布搞定，大大增加工作效率。适用于国内外各种型号、规格的金相抛光机，尤其适用于半自动抛光机和抛光膏配合使用。
适合检测项目：宏观金相、平均晶粒度、非金属夹杂、显微组织、金属间有害相、低倍组织、电化学腐蚀、晶间腐蚀等金属材料的磨样。
</span></p>
                            <p
                            style={{textAlign: "center"}}></p>
                            <br/>
                            <h2><span
                            style={{fontSize: 16, color: '#000'}}><strong>详细介绍</strong></span></h2>
                            <p>
                                <span
                                    style={{color: '#000'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本公司研发的金相抛光布是专门用于金属材料、玻璃、陶瓷的金相制样时的精抛。适用国内、外各种型号、规格的金相抛光机，尤其适用于半自动抛光机和抛光膏配合使用。抛光膏价格便宜，相对于大多数的抛光辅材只有其价格的十分之一，本产品特点就是配合价格低廉的抛光膏而研制的，大大减少公司成本。本公司自研的抛光布采用真丝丝绒作为主材料，采用350克高克重布料，丝绒的特点是长绒毛、真丝料、密度高，常见的用于制作高端旗袍。本产品在此基础上加入高分子材料，使得其更加耐磨，寿命更长，大大减少公司成本。金相抛光织物系列由抛光层、存储磨料层、保护层等多层组成，相连部分采用美国3M胶，存放五年都都不会开胶。<br/></span>
                            </p>

                            <img src={jx1} width={'70%'} style={{display:"block",margin:"auto"}}/>
                            <img src={jx2} width={'70%'} style={{display:"block",margin:"auto"}}/>
                            <img src={jx3} width={'70%'} style={{display:"block",margin:"auto"}}/>
                            <img src={jx4} width={'70%'} style={{display:"block",margin:"auto"}}/>
                            <h2 className='Fanya_three_TT'><span><strong>工厂实拍</strong></span></h2>
                            <p>
                                <img style={{float:"left",width:'23%',marginLeft:'2%'}} src={jxgc1}/>
                                <img style={{float:"left",width:'23%',marginLeft:'2%'}} src={jxgc2}/>
                                <img style={{float:"left",width:'23%',marginLeft:'2%'}} src={jxgc3}/>
                                <img style={{float:"left",width:'23%',marginLeft:'2%', height:313}} src={jxgc4}/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/*<img src={xia} width={'100%'} style={{zIndex:999,position:"relative",marginTop:80,display:"block"}}/>*/}

            <div className="all_fnav is_PC" style={{zIndex: 999, position: "relative"}}>
                <div style={{background: '#fff', height: 120}}></div>
                <div className="warp">
                    <div className={'b'}>
                        <img src={logo22} height={108} style={{float: "left"}}/>
                        <div style={{
                            float: "right",
                            lineHeight: '108px',
                            fontSize: '26px',
                            paddingRight: 40
                        }}>致力于我国实验室建设、 认证、 扩项、 监督评审、 一站式解决方案
                        </div>
                    </div>
                    <div className="Fanya_footer_nav" style={{color: '#FFF'}}>
                        <ul>
                            <li>

                                <div className="Fanya_footer_nav_TT">CMA资质认定</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>检验检测机构CMA资质认定</dt>
                                        <dt>机动车检验机构CMA资质认定</dt>
                                        <dt>司法鉴定机构CMA资质认定</dt>
                                        <dt>刑事技术机构CMA资质认定</dt>
                                    </dl>
                                </div>
                            </li>
                            <li>
                                <div className="Fanya_footer_nav_TT">CNAS认证认可</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>ISO17025检测/校准实验室CNAS认可</dt>
                                        <dt>ISO17020检验机构CNAS认可</dt>
                                        <dt>ISO15189医学实验室CNAS认可</dt>
                                        <dt>DILAC国防实验室认可</dt>
                                    </dl>
                                </div>
                            </li>
                            <li style={{marginRight: 15}}>
                                <div className="Fanya_footer_nav_TT">联系我们</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>电话：0574-87636695</dt>
                                        <dt>认证热线：18957441030（胡老师）</dt>
                                        <dt>地址：宁波市高新区检验检测园冬青路258号A幢</dt>
                                        <dt></dt>
                                    </dl>
                                </div>
                            </li>
                            <li>
                                <div className="Fanya_footer_sub" style={{position: "relative"}}>
                                    <img src={weixing} width={160}/>
                                    <div style={{
                                        background: 'red',
                                        borderRadius: 16,
                                        lineHeight: '32px',
                                        textAlign: "center",
                                        width: 160,
                                        marginTop: 10
                                    }}>扫码咨询
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="all_Fanya_copyright">
                <div className="warp">
                    <div className="Fanya_copyright">版权所有 ◎ 浙江安鉴检测认证服务有限公司 <a
                        href="http://beian.miit.gov.cn/" target="_blank">浙ICP备2024103308号-1</a></div>
                </div>
            </div>
        </>
);
};
export default App;
