import {FloatButton} from 'antd';
import {useEffect, useState} from 'react';
import './ContentIndex.css';
const logoImg = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/logo2.png';
const yemei = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/关于我们.jpg';
const gs = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/公司图片(1).jpg';
const xia = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/xq/logo至于每一个介绍最下方.png';
const logo22 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/logo22.png';
const weixing = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/weixing.jpg';
const App = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <FloatButton.BackTop visibilityHeight={0} style={{zIndex:9999999999999999}}/>
            <div className="fixed is_PC"></div>
            <div className="all_width is_PC">
                <div className="warp">
                    <div className="header">
                         <div className="logo"><a href="#/ContentIndex" style={{float:"left",marginRight:15}}><img src={logoImg} height={108}/></a></div>
                        <div className="new_nav">
                           <ul>
                                <li><a href="#/ContentIndex">首页</a></li>
                                <li><a  href="#/cnas">CNAS认可</a></li>
                                <li><a  href="#/cma">CMA认定</a></li>
                                <li><a  href="#/fanghuo">防火检测</a></li>
                                <li><a  href="#/haishui">海水腐蚀</a></li>
                                <li><a  href="#/jingxiang">金相抛光布</a></li>
                                <li><a   href="#/about">关于我们</a></li>
                            </ul>
                        </div>
                        <div className="vub_B" style={{position:"fixed",top:"30px"}}>联系电话： 18957441030</div>
                    </div>
                </div>
            </div>
            {/*<img src={yemei} width={'100%'}/>*/}
            <div style={{position:'relative'}}>
                {/*<img src={bannerBG}/>*/}
                <img src={yemei} width={'100%'}/>
                <div style={{position:"absolute",top:'35%',left:'100px',fontSize:40,color:'#24285c',fontWeight:"bold",width:200,textAlign:"center"}}>
                    <span>关于我们</span><br />
                    {/*<span>浙江安鉴检测认证</span><br />*/}
                    <span style={{fontWeight:100,fontSize:24}}>浙江安鉴检测认证</span>
                </div>
            </div>
            <div className="Kanya_banner_jianjie">
                <div className="banner_jianjie" style={{height:860}}>
                    <div className="banner_jianjie_A" style={{  }}>安鉴认证简介</div>
                    <div className="banner_jianjie_B" style={{width:600,fontSize:16}}>
                        浙江安鉴检测认证服务有限公司是专门致力于我国实验室认可的咨询事业。目前公司开展的主要咨询项目有CNAS资质认证、CMA认可咨询、检测/校准实验室认可、实验室资质认定、检验机构认可、医学实验室认可、司法鉴定、国防实验室认可、能力验证/测量审核、API体系认可及专业技能培训等。<br/>
                        公司2015年建立了以宁波高新区总部、在江苏无锡和南京建有分公司，迄今已为超过 500 家大中型企业提供专业技术咨询服务。服务客户涉及建筑、环境、食品、药品、冶金、电子、电器、汽车、水处理、血液、疾控、医学检验、石油、化工、钢铁、塑料、机车零部件、纺织、校准等行业。为客户提供各位周到、便捷的咨询服务。<br/>

                        <p style={{fontWeight:"bold",color:'#0c2166'}}>坚实的对外关系</p>
                        公司拥有资深实验室认可专家二十余人，核心咨询团队主要由国家实验室认可评审专家 、检查机构认可评审专家、商检系统评审专家、实验室资质认定评审专家的资深咨询专家组成。咨询团队为每个客户都提供了专业、高效、优质的咨询指导，并且得到了客户、认可机构和同行业的普遍认可，且与认可机构建立了良好的业务关系，成为实验室认可咨询界的品牌企业。
                        <br /><br /><br /><br />
                        <p style={{fontWeight:"bold",color:'#0c2166',fontSize:32}}>联系我们</p>
                        电话：0574-87636695<br />
                        认证热线：18957441030（胡老师）<br />
                        地址：宁波市高新区检验检测园冬青路258号A幢
                    </div>
                    <div className="banner_jianjie_C is_PC" style={{width:700,top:133}}>
                        <div className="Scroll-left">
                            <img src={gs} width={'100%'}/>
                        </div>
                    </div>
                </div>
            </div>
            <img src={xia} width={'100%'} style={{zIndex:999,position:"relative",marginTop:80,display:"block"}}/>

             <div className="all_fnav is_PC" style={{zIndex:999,position:"relative"}}>
                 <div style={{background:'#fff',height:80}}></div>
                <div className="warp">
                    <div className={'b'}>
                        <img src={logo22} height={108} style={{float:"left"}}/>
                        <div style={{float:"right",lineHeight:'108px',fontSize:'26px',paddingRight:40}}>致力于我国实验室建设、 认证、 扩项、 监督评审、 一站式解决方案</div>
                    </div>
                    <div className="Fanya_footer_nav" style={{color:'#FFF'}}>
                        <ul>
                            <li>

                                <div className="Fanya_footer_nav_TT">CMA资质认定</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>检验检测机构CMA资质认定</dt>
                                        <dt>机动车检验机构CMA资质认定</dt>
                                        <dt>司法鉴定机构CMA资质认定</dt>
                                        <dt>刑事技术机构CMA资质认定</dt>
                                    </dl>
                                </div>
                            </li>
                            <li><div className="Fanya_footer_nav_TT">CNAS认证认可</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>ISO17025检测/校准实验室CNAS认可</dt>
                                        <dt>ISO17020检验机构CNAS认可</dt>
                                        <dt>ISO15189医学实验室CNAS认可</dt>
                                        <dt>DILAC国防实验室认可</dt>
                                    </dl>
                                </div>
                            </li>
                            <li style={{marginRight:15}}>
                                <div className="Fanya_footer_nav_TT">联系我们</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>电话：0574-87636695</dt>
                                        <dt>认证热线：18957441030（胡老师）</dt>
                                        <dt>地址：宁波市高新区检验检测园冬青路258号A幢</dt>
                                        <dt></dt>
                                    </dl>
                                </div>
                            </li>
                            <li>
                                <div className="Fanya_footer_sub" style={{position:"relative"}}>
                                    <img src={weixing} width={160} />
                                    <div style={{background:'red',borderRadius:16,lineHeight:'32px',textAlign:"center",width:160,marginTop:10}}>扫码咨询</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="all_Fanya_copyright">
                <div className="warp">
                    <div className="Fanya_copyright">版权所有 ◎ 浙江安鉴检测认证服务有限公司 <a
                        href="http://beian.miit.gov.cn/" target="_blank">浙ICP备2024103308号-1</a></div>
                </div>
            </div>
        </>
    );
};
export default App;
